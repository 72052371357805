import {Injectable} from '@angular/core';
import {Error} from 'tslint/lib/error';


export interface Config {
    maxWidth: number;
    maxHeight: number;
}

const MAX_WIDTH = 800;
const MAX_HEIGHT = 600;

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor() {
    }

    // resizeImage(file: File, config: Config) {
    //     if (!file.type.match(/image.*/)) {
    //         throw new Error('File type does not match an image')
    //     }
    //
    //     const fr = new FileReader();
    //     fr.onload = (e) => {
    //         const image = new Image();
    //         image.onload = () => {
    //
    //         }
    //         image.src = <string>fr.result;
    //
    //     }
    //     fr.readAsDataURL(file);
    //     return this.scaleImage(image, config);
    //
    // }

    scaleImage(image, config: Config) {
        let canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
        console.log('HALVING SCALE', canvas.width, canvas.height, config);

        while (canvas.width >= (2 * config.maxWidth) || canvas.height >= (2 * config.maxHeight)) {
            console.log('HALVING SCALE', canvas.width, canvas.height);
            canvas = this.halfScaleCanvas(canvas);
        }

        if (canvas.width >= config.maxWidth) {
            const scale = config.maxWidth / canvas.width;
            canvas = this.scaleCanvasBilinearly(canvas, scale);
        }

        if (canvas.height >= config.maxHeight) {
            const scale = config.maxHeight / canvas.height;
            canvas = this.scaleCanvasBilinearly(canvas, scale);
        }

        return canvas;
    }

    private halfScaleCanvas(canvas: HTMLCanvasElement): HTMLCanvasElement {
        const halfCanvas = document.createElement('canvas');
        halfCanvas.width = canvas.width / 2;
        halfCanvas.height = canvas.height / 2;
        halfCanvas.getContext('2d').drawImage(canvas, 0, 0, halfCanvas.width, halfCanvas.height);
        return halfCanvas;
    }

    private scaleCanvasBilinearly(canvas: HTMLCanvasElement, scale: number) {
        console.log('SCALE', scale);
        let scaledCanvas = document.createElement('canvas');

        scaledCanvas.width = canvas.width * scale;
        scaledCanvas.height = canvas.height * scale;

        let srcImgData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);
        let destImgData = scaledCanvas.getContext('2d').createImageData(scaledCanvas.width, scaledCanvas.height);

        this.applyBilinearInterpolation(srcImgData, destImgData, scale);

        scaledCanvas.getContext('2d').putImageData(destImgData, 0, 0);

        return scaledCanvas;
    }

    private applyBilinearInterpolation = function (srcCanvasData, destCanvasData, scale) {
        function inner(f00, f10, f01, f11, x, y) {
            let un_x = 1.0 - x;
            let un_y = 1.0 - y;
            return (f00 * un_x * un_y + f10 * x * un_y + f01 * un_x * y + f11 * x * y);
        }

        let i, j;
        let iyv, iy0, iy1, ixv, ix0, ix1;
        let idxD, idxS00, idxS10, idxS01, idxS11;
        let dx, dy;
        let r, g, b, a;
        for (i = 0; i < destCanvasData.height; ++i) {
            iyv = i / scale;
            iy0 = Math.floor(iyv);
            // Math.ceil can go over bounds
            iy1 = (Math.ceil(iyv) > (srcCanvasData.height - 1) ? (srcCanvasData.height - 1) : Math.ceil(iyv));
            for (j = 0; j < destCanvasData.width; ++j) {
                ixv = j / scale;
                ix0 = Math.floor(ixv);
                // Math.ceil can go over bounds
                ix1 = (Math.ceil(ixv) > (srcCanvasData.width - 1) ? (srcCanvasData.width - 1) : Math.ceil(ixv));
                idxD = (j + destCanvasData.width * i) * 4;
                // matrix to vector indices
                idxS00 = (ix0 + srcCanvasData.width * iy0) * 4;
                idxS10 = (ix1 + srcCanvasData.width * iy0) * 4;
                idxS01 = (ix0 + srcCanvasData.width * iy1) * 4;
                idxS11 = (ix1 + srcCanvasData.width * iy1) * 4;
                // overall coordinates to unit square
                dx = ixv - ix0;
                dy = iyv - iy0;

                r = inner(srcCanvasData.data[idxS00], srcCanvasData.data[idxS10], srcCanvasData.data[idxS01], srcCanvasData.data[idxS11], dx, dy);
                destCanvasData.data[idxD] = r;

                g = inner(srcCanvasData.data[idxS00 + 1], srcCanvasData.data[idxS10 + 1], srcCanvasData.data[idxS01 + 1], srcCanvasData.data[idxS11 + 1], dx, dy);
                destCanvasData.data[idxD + 1] = g;

                b = inner(srcCanvasData.data[idxS00 + 2], srcCanvasData.data[idxS10 + 2], srcCanvasData.data[idxS01 + 2], srcCanvasData.data[idxS11 + 2], dx, dy);
                destCanvasData.data[idxD + 2] = b;

                a = inner(srcCanvasData.data[idxS00 + 3], srcCanvasData.data[idxS10 + 3], srcCanvasData.data[idxS01 + 3], srcCanvasData.data[idxS11 + 3], dx, dy);
                destCanvasData.data[idxD + 3] = a;
            }
        }
    }
}
