import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {getTaxRateError} from '../product-utils';

@Directive({
    selector: '[appTaxValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: TaxValidatorDirective, multi: true}],

})
export class TaxValidatorDirective implements Validator {

    constructor() {
    }

    validate(control: AbstractControl): {[key: string]: any} {
        return this.validateTaxRate()(control);
    }

    validateTaxRate(): ValidatorFn {
        return (control: AbstractControl) => {
            const taxStr = control.value || '';
            return getTaxRateError(taxStr);
        }
    }
}
