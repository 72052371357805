/**
 * Created by Karl on 14/02/2017.
 */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProductService} from "../services/product.service";
import {Location} from "@angular/common";
import {Product, ProductFormData} from '../../shared/models/product';
import {GAPI} from '../../gapi';
import {
    DEFAULT_TAX_RATE,
    parsePrice,
    getDefaultProductImageUrl,
    parseTax,
    SERVICE_UNAVAILABLE,
    SERVICE_UNAVAILABLE_MSG,
    INTERNAL_SERVER_ERROR,
    INTERNAL_ERROR_MSG,
    allowNumericKeypressOnly, getParam
} from '../product-utils';
import {generateBarcode} from '../barcode-utils';
import {Subject, Observable, of} from 'rxjs';
import {switchMap, debounceTime, tap, map, filter, catchError} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageResponse} from '../../index';
import {ImageService} from '../../image.service';


export const KEY_PARAM = 'key';
export const MODE_PARAM = 'mode';

export const MODE_VIEW = 'view';
export const MODE_EDIT = 'edit';
export const MODE_ADD = 'add';


const DEBOUNCE_TIME = 150;

@Component({
    selector: 'app-add-product',
    templateUrl: 'product-form.component.html',
    styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit, OnDestroy{

    readonly MODE_VIEW = MODE_VIEW;
    readonly MODE_EDIT = MODE_EDIT;

    barcodeChangedEvent: Subject<string> = new Subject<string>();
    isBarcodeAvailable: boolean;

    mode: string;

    @ViewChild('productForm') productForm;

    productFormData: ProductFormData = {
        name: null,
        description: null,
        categories: [],
        price: null,
        taxRate: DEFAULT_TAX_RATE,
        photo: null,
        photoUrl: getDefaultProductImageUrl(),
        barcode: null,
        newBarcode: null,
        barcodes: [],
        websafeKey: null,
    };

    isSaving: boolean;
    saveErrorMessage: string;

    isResizingImage: boolean = false;

    constructor(private productService: ProductService,
                private imageService: ImageService,
                private location: Location,
                private route: ActivatedRoute,
                private router: Router) {
    }


    ngOnInit(): void {
        const queryParams$ = this.route.queryParams;
        queryParams$.pipe(
            tap( params => this.setMode(params)),
            map( params => getParam(KEY_PARAM, params)),
            switchMap( key => key != null ? this.getProduct(key) : of(null)),
            filter( product => product != null )
        ).subscribe(product => this.setProductFormData(product));

        this.barcodeChangedEvent.pipe(
            debounceTime( DEBOUNCE_TIME ),
            tap( barcode => this.productFormData.newBarcode = barcode ),
            map( barcode => this.productFormData.barcode === barcode ? null :  barcode),
            switchMap( barcode => this.productService.isBarcodeAvailable(barcode).pipe(
                catchError( error => of(null))
            ))
        ).subscribe(isAvailable => this.isBarcodeAvailable = isAvailable );
    }

    private setMode(params: Params) {
        const key = getParam(KEY_PARAM, params);
        const mode = getParam(MODE_PARAM, params);
        if (key == null) {
            this.mode = MODE_ADD;
            return;
        }

        if (mode == null) {
            throw new Error(`Product key is available. Mode has not been set.`);
        }

        if (mode !== MODE_VIEW && mode !== MODE_EDIT) {
            throw new Error(`Mode should either be ${MODE_VIEW} or ${MODE_EDIT}. Found ${mode}.`)
        }

        this.mode = mode;
    }

    private getProduct(key: string): Observable<Product> {
        return this.productService.getProduct(key).pipe(
            catchError(error => this.handleGetProductError(error))
        );
    }

    private handleGetProductError(error: HttpErrorResponse): Observable<Product> {
        console.log('Error', error);
        return of(null );
    }

    private setProductFormData(product: Product) {
        if (product == null) {
            return;
        }

        // Assign all product data.
        this.productFormData = Object.assign(this.productFormData, product);
        this.productFormData.photo = null;

        if (this.mode == MODE_EDIT) {
            this.setupEditMode();
        }

    }

    canSave() {
        const newBarcode = this.productFormData.newBarcode;
        const oldBarcode = this.productFormData.barcode;
        const barcodeAvailable =  this.isBarcodeAvailable;
        return (this.mode === MODE_ADD || this.mode === MODE_EDIT)
            && !this.productForm.form.invalid
            && !this.isSaving
            && !this.isResizingImage
            && (this.mode === MODE_EDIT ? barcodeAvailable || newBarcode === oldBarcode: barcodeAvailable);
    }

    get currentBarcode() {
        return this.productFormData.newBarcode != null
            ? this.productFormData.newBarcode
            : this.productFormData.barcode;
    }

    back() {
        if (this.mode == null || this.mode === MODE_VIEW) {
            this.router.navigate(['products'], {replaceUrl: true});
            return;
        }
        this.location.back();
    }

    edit() {
        this.router.navigate(
            ['product'],
            {queryParams: {key: this.productFormData.websafeKey, mode: MODE_EDIT}}
        );
        this.setupEditMode();
    }

    private setupEditMode() {
        // Copy across barcode data to check for differences.
        this.productFormData.newBarcode = this.productFormData.barcode;

        // Barcode should be unchanged.
        this.isBarcodeAvailable = null;
    }


    onKeypress(event: KeyboardEvent) {
        allowNumericKeypressOnly(event, false);
    }

    onPriceChange(priceStr: string) {
        priceStr = priceStr || '';

        // Force positive numbers only.
        priceStr = priceStr.replace(/[^0-9.]/g, "");
        this.productFormData.price = parsePrice(priceStr);
    }

    displayPrice() {
        return this.productFormData.price == null ? '' : '$' + this.productFormData.price;
    }

    onTaxChange(percentStr: string) {
        if (percentStr == null){
            this.productFormData.taxRate = null;
            return
        }

        percentStr = percentStr.trim();
        if (percentStr === '') {
            this.productFormData.taxRate = null;
            return
        }

        this.productFormData.taxRate =  parseTax(percentStr);
    }

    displayTax() {
        if (this.productFormData.taxRate == null) {
            return '';
        }

        return this.productFormData.taxRate == null ? '' :  '' + this.productFormData.taxRate * 100;
    }

    onImageChanged(files: FileList) {
        const targetFile = files[files.length-1]
        const fr: FileReader = new FileReader();
        this.isResizingImage = true;
        fr.onload = () => {
            const image = new Image();
            image.onload = () => {
                this.isResizingImage = false;
                const canvas = this.imageService.scaleImage(image, {maxWidth: 300, maxHeight: 300});
                this.productFormData.photoUrl = canvas.toDataURL('image/jpeg', 1.0);
                this.productFormData.photo = this.dataURItoFile(this.productFormData.photoUrl, targetFile.name);
                console.log('The file', this.productFormData.photo)
            };
            image.onerror = () => {
                this.isResizingImage = false;
            };
            image.src = <string> fr.result;
        };
        fr.onerror = () => {
            this.isResizingImage = false;
        };
        fr.readAsDataURL(targetFile);
    }

    private dataURItoFile(dataURI: string, filename: string): File {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([new Blob([ia], {type:mimeString})], filename);
    }

    onBarcodeChanged(barcodeStr: string) {
        if (barcodeStr != null) {
            barcodeStr = barcodeStr.trim();
        }
        this.barcodeChangedEvent.next(barcodeStr);
    }


    barcodeUrl(barcode: string) {
        return GAPI.barcode(barcode)
    }

    get qrcodeUrl() {
        if (!this.productFormData.websafeKey){
            return null;
        }
        return GAPI.qrCode(this.productFormData.websafeKey)
    }

    generateBarcode() {
        this.onBarcodeChanged(generateBarcode());
    }

    onClearBarcode() {
        this.onBarcodeChanged(null);
    }


    onSave() {
        if (this.mode == MODE_VIEW) {
            throw new Error(`Can only save from either ${MODE_ADD} or ${MODE_EDIT} mode.`)
        }

        if(this.isSaving) {
            return;
        }

        this.isSaving = true;
        this.saveErrorMessage = null;

        if (this.mode === MODE_ADD) {
            this.createProduct()
        } else {
            this.updateProduct();
        }

    }

    deleteProduct() {
        if (!this.productFormData.websafeKey) {
            return;
        }
        this.isSaving = true;
        this.productService.deleteProduct(this.productFormData.websafeKey).pipe(
            tap(_ => this.isSaving = false),
            catchError( error => this.handleSaveProductError(error))
        ).subscribe( response => this.onDeleteProduct(response));
    }

    private onDeleteProduct(response: MessageResponse) {
        if (!response || !response.success) {
            return;
        }

        this.isBarcodeAvailable = null;
        this.mode = null;
        this.isSaving = false;
        this.saveErrorMessage = null;
        this.productFormData = {
            name: null,
            description: null,
            categories: [],
            price: null,
            taxRate: DEFAULT_TAX_RATE,
            photo: null,
            photoUrl: getDefaultProductImageUrl(),
            barcode: null,
            newBarcode: null,
            barcodes: [],
            websafeKey: null,
        };

        this.back();
    }

    private createProduct() {

        this.productService.createProduct(this.productFormData).pipe(
            tap(_ => this.isSaving = false),
            catchError( error => this.handleSaveProductError( error ))
        ).subscribe(
            response => this.onSaveResult(response),
            error => console.log(error),
            () => console.log('Create product completed')
        );
    }

    private updateProduct() {
        this.productService.updateProduct(this.productFormData).pipe(
            tap(_ => this.isSaving = false),
            catchError( error => this.handleSaveProductError( error ))
        ).subscribe(
            response => this.onSaveResult(response)
        );
    }


    onSaveResult(product: Product) {
        if (!product) {
            return;
        }

        // Clear any updated values when the product has been saved.
        this.isSaving = false;
        this.isBarcodeAvailable = null;
        this.saveErrorMessage = null;
        this.productFormData.newBarcode = null;
        this.productFormData.photo = null;

        this.router.navigate(
            ['product'],
            {queryParams: {key: product.websafeKey, mode: MODE_VIEW}, replaceUrl: true})
    }


    handleSaveProductError(error: HttpErrorResponse) {
        this.isSaving = false;

        if (error.status ===  SERVICE_UNAVAILABLE) {
            this.saveErrorMessage = SERVICE_UNAVAILABLE_MSG;
            return of( null );
        }

        if (error.status >= INTERNAL_SERVER_ERROR) {
            console.log('Error msg', error);
            this.saveErrorMessage = INTERNAL_ERROR_MSG;
            return of( null );
        }

        const errorMsg = error!.error!.error!.message;
        this.saveErrorMessage = errorMsg != null ? errorMsg : SERVICE_UNAVAILABLE;
        return of( null );
    }

    ngOnDestroy(): void {
        this.barcodeChangedEvent.complete();
    }

}
