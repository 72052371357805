/**
 * Created by Karl on 22/04/2017.
 */

import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LandingPageComponent} from "./landing/landing.component";
import {SetupComponent} from "./setup/setup.component";
import {AdminRoutingModule} from "./admin-routing.module";
import {InviteUserComponent} from "./mgmt/user/invite-user.component";
import {VerifyInviteComponent} from "./setup/user/verify-user.component";
import {SignUpComponent} from "./setup/user/signup.component";
import {VerifyEmailComponent} from "./setup/user/verify-email.component";
import {WelcomeUserComponent} from "./mgmt/user/welcome-user.component";
import {AdminComponent} from "./admin.component";
import {VendorNotSetupComponent} from './vendor-not-setup/vendor-not-setup.component';

@NgModule({
    imports: [BrowserModule, CommonModule, FormsModule, AdminRoutingModule],
    declarations: [
        SetupComponent,
        AdminComponent,
        LandingPageComponent,
        SignUpComponent,
        InviteUserComponent,
        VerifyInviteComponent,
        VerifyEmailComponent,
        WelcomeUserComponent,
        VendorNotSetupComponent,
    ],
    exports: [
        SignUpComponent
    ],
    bootstrap: []
}) export class AdminModule {}
