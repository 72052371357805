import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '../index';
import {fadeInOut} from '../animations';
import {StoreService} from '../store.service';
import {first, tap, catchError, mapTo} from 'rxjs/operators';
import {of} from 'rxjs'
import {HttpErrorResponse} from '@angular/common/http';
import {INTERNAL_ERROR_MSG, INTERNAL_SERVER_ERROR, SERVICE_UNAVAILABLE, SERVICE_UNAVAILABLE_MSG} from '../../utils';
import {MessageResponse} from '../../index';

@Component({
    selector: 'app-delete-store',
    templateUrl: './delete-store.component.html',
    styleUrls: ['./delete-store.component.css'],
    animations: [fadeInOut]
})
export class DeleteStoreComponent implements OnInit {

    public static readonly DELETED_STORE = 'Deleted store';

    @Input()
    store: Store;

    errorMessage: string;

    loading: boolean = false;

    constructor(private storeService: StoreService, public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    onDeleteStore() {
        this.loading = true;
        this.storeService.deleteStore(this.store.websafeKey).pipe(
            catchError( error => this.handleError(error)),
            first(),
            tap( _ => this.loading = false)
        ).subscribe(response => this.onStoreDeleted(response))
    }

    handleError(error) {
        console.log('ERROR', error);
        this.loading = false;
        if (error instanceof HttpErrorResponse) {
            this.errorMessage = this.getHttpErrorMessage(error);
        } else {
            this.errorMessage = SERVICE_UNAVAILABLE_MSG;
        }

        return this.storeService.getStore(this.store.websafeKey).pipe(
            catchError( error => of(null)),
            tap(store => this.refreshStore(store)),
            mapTo(null)
        )

    }


    refreshStore(store: Store | null) {
        if (store == null || store == undefined) {
            return;
        }

        this.store.active = store.active;
    }
    private getHttpErrorMessage(error: HttpErrorResponse) {
        if (error.status === SERVICE_UNAVAILABLE) {
            return SERVICE_UNAVAILABLE_MSG;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            return INTERNAL_ERROR_MSG;
        }

        const clientError = error!.error!.error!.message;
        return clientError == null ? SERVICE_UNAVAILABLE_MSG : clientError;
    }

    onStoreDeleted(response: MessageResponse | null) {
        if (response == null) {
            return;
        }

        if (!response.success) {
            this.errorMessage = response.message;
            return;
        }

        this.activeModal.close(DeleteStoreComponent.DELETED_STORE)
    }
}
