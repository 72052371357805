import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {getPriceError} from '../product-utils';

@Directive({
    selector: '[appPriceValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: PriceValidatorDirective, multi: true}],
})
export class PriceValidatorDirective implements Validator {

    constructor() {
    }

    validate(control: AbstractControl): {[key: string]: any} {
        return this.validatePrice()(control);
    }

    validatePrice(): ValidatorFn {
        return (control: AbstractControl) => {
            const priceStr = control.value || '';
            return getPriceError(priceStr);
        }
    }
}
