/**
 * Created by Karl on 25/8/17.
 */

import {Injectable} from "@angular/core";
import {AdminService} from '../admin/admin.service';
import {HttpClient} from '@angular/common/http';
import {buildUrl, getAuthHeaders} from '../utils';
import {environment} from '../../environments/environment';
import {switchMap, first, tap} from 'rxjs/operators'
import {of} from 'rxjs/index';
import {PurchaseSummary, Store, StoreMessages, StorePageResponse} from './index';
import {Observable} from 'rxjs/Rx'
import * as moment from 'moment'
import 'moment-timezone';
import {MessageResponse} from '../index';

export function watchPurchaseStream(store: Store) {
    const url = buildUrl(environment.streamUrl, ['store'], {storeId: store.websafeKey});
    window.open(url, '_blank')
}

@Injectable()
export class StoreService {

    constructor(private http: HttpClient, private adminService: AdminService) {}

    getStoresAround(center: google.maps.LatLng, radius: number, refresh?: boolean): Observable<StoreMessages> {
        if (center == null || radius == null) {
            return of({stores: []});
        }

        return this.adminService.getIdToken().pipe(
            switchMap(token => this.getStoresAroundFromServer(token, center, radius)),
            first(),
            tap( storesMessages => storesMessages.refresh = !!refresh)
        );
    }

    private getStoresAroundFromServer(token: string, center: google.maps.LatLng, radius: number) {
        const params = {
            lat: String(center.lat()),
            lng: String(center.lng()),
            radius: String(radius)
        };
        const url = buildUrl(environment.hostUrl, ['get_stores_around'], params);
        return this.http.get<StoreMessages>(url, {headers: getAuthHeaders(token)})
    }

    getStoresPage(center: google.maps.LatLng, offset: number, pageSize: number = 25, refresh?: boolean): Observable<StorePageResponse> {
        console.log('DEBUG, refresh?', refresh)
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.getStorePageFromServer(token, center, offset, pageSize)),
            tap( response => response.refresh = !!refresh),
            first()
        );
    }

    private getStorePageFromServer(token: string, center: google.maps.LatLng, offset: number, pageSize: number = 25) {
        const params = {
            lat: String(center.lat()),
            lng: String(center.lng()),
            offset: String(offset),
            pageSize: String(pageSize)
        };
        const url = buildUrl(environment.hostUrl, ['get_store_page'], params);
        return this.http.get<StorePageResponse>(url, {headers: getAuthHeaders(token)})
    }

    createStore(store: Store): Observable<Store> {
        return this.adminService.getIdToken().pipe(
            switchMap( token => this.createStoreOnServer(token, store ) ),
            first()
        )
    }

    private createStoreOnServer(token: string, store: Store) {
        const params = {
            address: store.address,
            suburb: store.suburb,
            lat: store.lat,
            lng: store.lng,
            active: store.active != null ? store.active : true
        };

        const url = buildUrl(environment.hostUrl, ['create_store'], {});
        return this.http.post<Store>(url, params, {headers: getAuthHeaders(token)})
    }

    getPurchaseSummary(store: Store): Observable<PurchaseSummary> {
        return this.adminService.getIdToken().pipe(
            switchMap( token => this.getPurchaseSummaryServer(token, store) ),
            first()
        )
    }

    private getPurchaseSummaryServer(token: string, store: Store) {
        const url = buildUrl(environment.hostUrl, ['get_purchase_summary'], {});
        const params = {
            storeKey: store.websafeKey,
            currentTime: moment().local().tz(moment.tz.guess()).format()
        };
        return this.http.post<PurchaseSummary>(url, params,{headers: getAuthHeaders(token)})
    }


    getStore(storeKey: string): Observable<Store> {
        return this.adminService.getIdToken().pipe(
            switchMap( token => this.getStoreOnServer(token, storeKey) ),
            first()
        )
    }

    private getStoreOnServer(token: string, storeKey: string): Observable<Store> {
        const url = buildUrl(environment.hostUrl, ['get_store'], {storeKey: storeKey});
        return this.http.get<Store>(url, {headers: getAuthHeaders(token)})
    }

    markStoreActivity(storeKey: string, active: boolean): Observable<Store> {
        return this.adminService.getIdToken().pipe(
            switchMap( token => this.markStoreActivityOnServer(token, storeKey, active)),
            first()
        )
    }


    private markStoreActivityOnServer(token: string, storeKey: string, active: boolean) {
        const url = buildUrl(environment.hostUrl, ['mark_store_activity'], {});
        const params = {
            storeKey: storeKey,
            active: active,
        };
        return this.http.post<Store>(url, params, {headers: getAuthHeaders(token)})
    }

    deleteStore(storeKey: string): Observable<MessageResponse> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.deleteStoreOnServer(token, storeKey) ),
            first()
        )
    }

    private deleteStoreOnServer(token: string, storeKey: string): Observable<MessageResponse> {
        const url = buildUrl(environment.hostUrl, ['delete_store'], {websafeKey: storeKey});
        return this.http.delete<MessageResponse>(url, {headers: getAuthHeaders(token)})
    }
}
