/**
 *
 * Created by Karl on 14/3/18.
 */

import {Directive, Input} from "@angular/core";
import {NG_VALIDATORS, Validator, AbstractControl} from "@angular/forms";
import {endDateValidationFn} from '../validators/date-validator';

@Directive({
    selector: '[endDateValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: EndDateValidatorDirective, multi: true}]
})
export class EndDateValidatorDirective implements Validator {

    @Input("endDateValidator") startDate: string;

    validate(control: AbstractControl): {[key: string]: any} {
        console.log(this.startDate ? endDateValidationFn(new Date(this.startDate))(control) : null);
        return this.startDate
            ? endDateValidationFn(new Date(this.startDate))(control)
            : {'reason': 'Start date is not defined'};
    }
}
