import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {Directive, Input} from '@angular/core';


export interface RangeParams {
    min: number;
    max: number;
}

export function validateRange(range: RangeParams): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        const minimum = range.min === null ? Number.MIN_VALUE : range.min;
        const maximum = range.max === null ? Number.MAX_VALUE : range.max;

        console.log('Is this being evaulated?', control.value)
        if (control.value < minimum) {
            return {'reason': 'Current value is below specified minimum.'}
        }

        if (maximum < control.value) {
            return {'reason': 'Current value is below specified maximum.'}
        }

        return null;
    }
}

@Directive({
    selector: '[range]',
    providers: [{provide: NG_VALIDATORS, useExisting: RangeValidatorDirective, multi: true}]

})
export class RangeValidatorDirective implements Validator {

    @Input('range') range: RangeParams;

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.range ? validateRange(this.range)(control) : {'reason': 'Requirement not defined.'} ;
    }
}
