import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

    @Input() width: string = '100%';
    @Input() height: string = '100%';
    @Input() stroke: string = '#ddd';

    constructor() {
    }

    ngOnInit() {
    }

}
