import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {isValidBarcode} from '../barcode-utils';

@Directive({
    selector: '[appBarcodeValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: BarcodeValidatorDirective, multi: true}]

})
export class BarcodeValidatorDirective implements Validator {


    constructor() {
    }

    validate(control: AbstractControl): {[key: string]: any} {
        return this.validateBarcode()(control);
    }

    validateBarcode(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} => {
            const barcodeStr = control.value || '';
            return isValidBarcode(barcodeStr) ? null : {invalidBarcode: 'Barcode is an invalid string'};
        }
    }

}
