import {Component, OnInit} from '@angular/core';
import {AdminService} from '../admin/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map} from 'rxjs/internal/operators';
import {MessageResponse} from '../index';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {of} from 'rxjs/index';
import {switchMap} from 'rxjs-compat/operator/switchMap';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    isSubmitting: boolean = false;
    requiredReset: boolean;

    password: string;
    confirmPassword: string;

    errorMessage: string;

    constructor(private adminService: AdminService, private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
        this.route.queryParams.pipe(
            map(params => 'required' in params ? params['required'] : false)
        ).subscribe(
            requiredReset => this.requiredReset = requiredReset
        );
    }

    get passwordsMatch() {
        if (this.password && !this.confirmPassword || !this.password && this.confirmPassword) {
            return false;
        }

        if (!this.password && !this.confirmPassword) {
            return true;
        }

        return this.password.trim() === this.confirmPassword.trim();
    }

    resetPassword() {
        this.isSubmitting = true;
        this.adminService.resetPassword(this.password.trim(), this.confirmPassword.trim()).pipe(
            catchError( error => this.handleError(error))
        ).subscribe(response => this.onResetPassword(response))
    }

    handleError(error: HttpErrorResponse): Observable<MessageResponse> {
        this.isSubmitting = false;
        this.errorMessage = error!.error!.error!.message || 'Service unavailable at the moment.';
        return of(null)
    }

    onResetPassword(response: MessageResponse) {
        this.isSubmitting = false;
        if (!!response && response.success) {
            this.router.navigate(['products']);
            return;
        }

        if (!!response) {
            this.errorMessage = response.message;
        }
    }
}
