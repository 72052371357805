import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {NgModule} from "@angular/core";
import {LoginComponent} from "./login/login.component";
import {SetupComponent} from "./admin/setup/setup.component";
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {LoggedInGuard} from './logged-in.guard';
import {ApiDocsComponent} from './documentation/api-docs/api-docs.component';
import {environment} from '../environments/environment';

/**
 * Created by Karl on 16/02/2017.
 */
const routerOptions: ExtraOptions = {
    enableTracing: !environment.production,
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
};

const routes: Routes = [
    {path: '', redirectTo: '/products', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'reset-password', component: ResetPasswordComponent, canActivate: [LoggedInGuard]},
    {path: 'setup', component: SetupComponent},
    {path: 'api', component: ApiDocsComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
