/**
 * Created by Karl on 20/8/17.
 */

import {Component, OnInit} from "@angular/core";
import {Router, Params, ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {AdminService} from "../../admin.service";

@Component({
    moduleId: module.id,
    selector: 'admin-verify-invite',
    template: `
            <div class="row">
                <div class="col-md-12">
                    <h3>Email</h3>
                    <p *ngIf="user">{{user.email}}</p>
                    <form class="form">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">First Name</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" [(ngModel)]="this.user.firstName" name="firstName" placeholder="First Name"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Last Name</label>
                            <div class="col-md-8">
                                <input class="form-control" type="text" [(ngModel)]="this.user.lastName" name="lastName" placeholder="Last Name"/>
                            </div>
                        </div>
                        <!-- TODO User form validators-->
                        <p *ngIf="!passwordsMatch">Passwords don't match</p>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Password</label>
                            <div class="col-md-8">
                                <input class="form-control" type="password" [(ngModel)]="password" name="password" placeholder="Password"/>
                               
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Confirm Password</label>
                            <div class="col-md-8">
                                <input class="form-control" type="password" [(ngModel)]="confirmPassword" name="confirm-password" placeholder="Confirm Password"/>
                            </div>
                        </div>
                        <button class="btn btn-primary btn-success" (click)="submitUser()">Submit</button>
                    </form>
                </div>
            </div>
        `,
})
export class VerifyInviteComponent implements OnInit {

    constructor(private adminService: AdminService, private router: Router, private route: ActivatedRoute) {

    }

    // TODO Turn this into a type.
    user: any = {};

    pendingUserKey: string;

    passwordsMatch: boolean = true;

    ngOnInit() {
        this.route.params.switchMap((params: Params) => this.identity(params["pendingUserKey"]))
            .subscribe(pendingUserKey => {
                console.log(pendingUserKey);
                this.getUserToken(pendingUserKey);
                this.pendingUserKey = pendingUserKey;
            })
    }

    set password(password: string) {
        this.user.password = password;
        this.passwordsMatch = this.user.password === this.user.confirmedPassword
    }

    set confirmPassword(password: string) {
        this.user.confirmedPassword = password;
        this.passwordsMatch = this.user.password === this.user.confirmedPassword
    }

    getUserToken(pendingUserKey: string) {
        this.adminService.verifyInvite(pendingUserKey).subscribe(msg => {
            console.log('Message: ', msg);
            this.user.email = msg.message;
        });
    }

    submitUser() {
        if (!this.user.email || !this.user.firstName || !this.user.lastName || !this.passwordsMatch) {
            console.log('ERROR?', this.user.email, this.user.firstName, this.user.lastName, this.passwordsMatch);
            return;
        }

        this.adminService.convertPendingUser({
            'email': this.user.email,
            'password': this.user.password,
            'firstName': this.user.firstName,
            'lastName': this.user.lastName
        }).do( result => {
                this.adminService.isLoggedIn$.subscribe( msg => console.log(msg) );
                return result;
        }).subscribe(msg => this.router.navigate(['setup', 'welcome']));
    }

    identity(param: string): Observable<string> {
        return Observable.create(observer => {
            observer.next(param);
            observer.complete();
        });
    }
}
