import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import range from 'lodash/range'
import {validateNumberBounds} from '../../utils';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_MAX_INDICATORS = 4;

const MAX_RESULTS_ACCURACY = 10000;

@Component({
    selector: 'app-page-indicator',
    templateUrl: './page-indicator.component.html',
    styleUrls: ['./page-indicator.component.css']
})
export class PageIndicatorComponent implements OnInit {

    faChevronLeft = faChevronLeft;
    faChevronRight = faChevronRight;

    @Input() pageSize = DEFAULT_PAGE_SIZE;
    @Input() maxIndicators = DEFAULT_MAX_INDICATORS;

    @Input() minOffset = 0;
    @Input() currentOffset = 0;
    @Input() numResults = 0;

    @Input() isLoading = false;

    @Output() pageSelected = new EventEmitter<number>();
    @Output() minOffsetChanged = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit() {
        this.validateInputs();
    }

    validateInputs() {
        validateNumberBounds('pageSize', this.pageSize, 0);
        validateNumberBounds('minOffset', this.minOffset, 0, this.numResults, true);
        validateNumberBounds('currentOffset', this.currentOffset, this.minOffset, this.numResults, true);
        validateNumberBounds('numResults', this.numResults, this.currentOffset, Number.MAX_VALUE, true);
        validateNumberBounds('maxIndicators', this.maxIndicators, 0);
    }

    get firstPage() {
        return Math.max(1, Math.ceil((this.minOffset+1)/this.pageSize));
    }

    get currentPage() {
        return Math.max(1, Math.ceil((this.currentOffset+1)/this.pageSize));
    }

    set currentPage(page: number) {
        const pageIndex = page - 1;
        this.minOffset = Math.max(0, (pageIndex - (this.maxIndicators-1)/2) * this.pageSize);
        this.currentOffset = Math.min((this.lastPage-1) * this.pageSize, pageIndex * this.pageSize);
        this.pageSelected.emit(page);
        this.minOffsetChanged.emit(this.minOffset);
    }

    get lastPage() {
        return Math.max(1, Math.ceil(this.numResults/this.pageSize));
    }

    getLastPageResult() {
        return Math.min(this.numResults, this.currentOffset + this.pageSize);
    }

    get pages() {
        const firstPage = Math.min(this.firstPage, Math.max(1, this.lastPage - this.maxIndicators + 1));
        const nPages = Math.min(this.lastPage, this.maxIndicators);
        const endPage = firstPage + nPages;
        return range(firstPage, endPage)
    }

    isNotNearLastPage() {
        const pages = this.pages;
        return pages.length > 0 && this.lastPage > pages[pages.length-1] + 1
    }

    isNotLastPage() {
        const pages = this.pages;
        return pages.length > 0 && this.lastPage > pages[pages.length-1]
    }

    get numResultsText() {
        const results = this.numResults || 0;
        if ( this.numResults > MAX_RESULTS_ACCURACY) {
            return '' + MAX_RESULTS_ACCURACY + '+'
        }
        return '' + results;
    }
}
