import {Component} from '@angular/core';
import {AdminService} from "../admin/admin.service";
import {Router} from "@angular/router";
import {of, Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
/**
 * Created by Karl on 18/04/2017.
 */

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ["./login.component.css"]
})
export class LoginComponent {

    logo: string;

    email = "";
    password = "";
    isSubmitting: boolean;
    errorMessage: string;

    constructor(private authService: AdminService, private router: Router) {
    }


    login() {
        this.isSubmitting = true;
        this.authService.loginWithEmailAndPassword(this.email, this.password).pipe(
            catchError( error => this.handleError(error)),
    ).subscribe(_ => this.onLogin())
    }

    private handleError(error: any): Observable<null> {
        this.isSubmitting = false;
        this.errorMessage = error!.message;
        return of(null);

    }

    private onLogin() {
        this.router.navigate(['products']);
    }
}
