/**
 * Created by Karl on 12/3/18.
 */

import { Component } from "@angular/core";
import { Location } from '@angular/common';
import 'rxjs/operators/first'
import {OffersService} from "../services/offers.service";


@Component({
    moduleId: module.id,
    selector: 'create-offer',
    templateUrl: './create-offer.component.html',
    styleUrls: ['./create-offer.component.css']
})
export class CreateOfferComponent {

    private fileReader: FileReader;
    imageUrl = '';
    offer: any = {
        title: '',
        body: '',
        image: null,
        startDate: null,
        endDate: null,
        vendor: null
    };

    constructor(private offerService: OffersService, private location: Location) {
        this.fileReader = new FileReader();
        this.fileReader.onload = () => this.onLoadImage();
    }

    onChangeImage(event: any) {
        const files = event.target.files;
        this.offer.image = event.target.files[files.length-1];
        this.fileReader.readAsDataURL(this.offer.image);
    }

    onLoadImage() {
        this.imageUrl =  <string>this.fileReader.result;
    }

    back() {
        this.location.back();
    }

    submitOffer() {
        this.offerService.createOffer(this.offer)
            .subscribe( offers => {
                console.log( 'OFFER', offers );
                this.back();
            });
    }
}
