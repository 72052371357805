import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageIndicatorComponent} from './page-indicator/page-indicator.component';
import {LoaderComponent} from './loader/loader.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { ShortAddressPipe } from '../short-address.pipe';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    declarations: [
        PageIndicatorComponent,
        LoaderComponent,
        ShortAddressPipe
    ],
    exports: [
        PageIndicatorComponent,
        LoaderComponent,
        ShortAddressPipe
    ]
})
export class SharedModule {
}
