/**
 * Created by Karl on 6/9/17.
 */

import {Component} from "@angular/core";
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent {

}
