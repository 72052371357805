/**
 * Created by Karl on 6/9/17.
 */
import {Routes, RouterModule} from "@angular/router";
import {DashboardComponent} from "./dashboard.component";
import {NgModule} from "@angular/core";
import {VendorSetupGuard} from "../vendor-setup-guard/vendor-setup.guard";

const routes: Routes = [{ path: 'dashboard', component: DashboardComponent, canActivate: [VendorSetupGuard]}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [VendorSetupGuard]
})
export class DashboardRoutingModule {}
