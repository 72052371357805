import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {getDisplayableCategories} from '../product-utils';
import {GAPI} from '../../gapi';
import {faTimes, faEye, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-product-preview',
    templateUrl: './product-preview.component.html',
    styleUrls: ['./product-preview.component.css']
})
export class ProductPreviewComponent implements OnInit, OnDestroy {

    faEye = faEye;
    faTimes = faTimes;
    faEdit = faEdit;
    faTrash = faTrash;

    @Input() product;

    @Output() close: EventEmitter<any> = new EventEmitter<any>();
    @Output() viewProduct: EventEmitter<string> = new EventEmitter<string>();
    @Output() editProduct: EventEmitter<string> = new EventEmitter<string>();
    @Output() deleteProduct: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
    }

    get categories() {
        return getDisplayableCategories(this.product)
    }

    get defaultBarcodeUrl() {
        return GAPI.barcode(this.product.barcode)
    }

    get qrCode() {
        return GAPI.qrCode(this.product.websafeKey);
    }
}
