import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';


export const UNDEF = -1;
export const ID_INDEX = 1;

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective implements AfterViewInit{

    @Input()
    topOffset: number = 0;

    @Output()
    public onIdChanged: EventEmitter<string> = new EventEmitter<string>();

    private currentIndex: number = UNDEF;

    private idEdges: [number, string][] = [];

    constructor(private el: ElementRef) {

    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: any) {
        const scrollTop = this.scrollTop;
        const currentIndex = this.getCurrentIndex(scrollTop);
        if (this.currentIndex != currentIndex) {
            this.currentIndex = currentIndex;
            this.onIdChanged.emit(this.getCurrentId());
        }
    }

    ngAfterViewInit(): void {
        const el = this.el.nativeElement;
        this.registerId(el);
        this.registerAllIds(el.children);
        console.log('ID EDGES', this.idEdges);
        const scrollTop = this.scrollTop;
        this.currentIndex = this.getCurrentIndex(scrollTop);
    }

    private registerAllIds(children: any) {
        if (!children) {
            return;
        }

        for (let child of children) {
            this.registerId(child);
            this.registerAllIds(child.children);
        }
    }

    private registerId(nativeElement: any): void {
        if (!nativeElement) {
            return;
        }

        const elementId = <string> nativeElement.getAttribute('id');
        if(!elementId) {
            return;
        }

        const offset = nativeElement.getBoundingClientRect().top + window.scrollY;
        this.idEdges.push([offset + this.topOffset, elementId]);
    }

    private getCurrentIndex(scrollTop: number) {
        const next =  this.idEdges.findIndex( ([offset, _]) => offset > scrollTop );
        if (this.idEdges && next == UNDEF) {
            // If there are ids and there are no more edges past the last item then the current index
            // must be the last edge
            return this.idEdges.length - 1;
        }
        return next - 1;
    }


    private getCurrentId() {
        if (this.currentIndex == UNDEF) {
            return null;
        }

        const edge = this.idEdges[this.currentIndex];
        if (!edge) {
            return null;
        }

        return edge[ID_INDEX];
    }

    get scrollTop() : number {
        return window.pageYOffset || document.documentElement.scrollTop;
    }
}
