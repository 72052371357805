import {Routes, RouterModule} from "@angular/router";
import {OffersDashboardComponent} from "./dashboard/offer-dashboard.component";
import {VendorSetupGuard} from "../vendor-setup-guard/vendor-setup.guard";
import {NgModule} from "@angular/core";
import {CreateOfferComponent} from "./create/create-offer.component";
import {OffersListComponent} from "./list/offers-list.component";

/**
 * Created by Karl on 12/3/18.
 */
const routes: Routes = [
    { path: 'offers', component: OffersDashboardComponent,
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full'},
            { path: 'list', component: OffersListComponent },
            { path: 'create', component: CreateOfferComponent}
        ],
        canActivate: [VendorSetupGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [VendorSetupGuard]
})
export class OffersRoutingModule {}
