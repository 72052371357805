import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Product} from '../../shared/models/product';
import {getDisplayableCategories} from '../product-utils';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

    @Input()
    product: Product;

    @Input()
    isSelected: boolean = false;

    @Output()
    onSelect: EventEmitter<Product> = new EventEmitter<Product>();

    @Output()
    onView: EventEmitter<Product> = new EventEmitter<Product>();

    constructor() {
    }

    ngOnInit() {
    }

    get categories() {
        return getDisplayableCategories(this.product);
    }

}
