/**
 * Created by Karl on 6/9/17.
 */
import {Routes, RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {VendorSetupGuard} from "../vendor-setup-guard/vendor-setup.guard";
import {StoreDashboardComponent} from "./dashboard/store-dashboard.component";
import {NewStoreComponent} from './new-store/new-store.component';

const routes: Routes = [
        {
            path: 'store',
            canActivate: [VendorSetupGuard],
            children: [
                {path: 'new', component: NewStoreComponent},
                {path: '', component: StoreDashboardComponent}
            ]}
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [VendorSetupGuard]
})
export class StoreRoutingModule {}
