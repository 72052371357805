/**
 * Created by Karl on 6/9/17.
 */
import {NgModule} from "@angular/core";
import {DashboardComponent} from "./dashboard.component";
import {DashboardRoutingModule} from "./dashboard-routing.module";
import { ReportGeneratorComponent } from '../report-generator/report-generator.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        SharedModule,
        NgbDatepickerModule,
        DashboardRoutingModule
    ],
    declarations: [
        DashboardComponent,
        ReportGeneratorComponent
    ],
    providers: []
})
export class DashboardModule {}
