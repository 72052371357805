export const KEY_BACKSPACE = 'Backspace';
export const KEY_ENTER = 'Enter';
export const KEY_DOWN = 'ArrowDown';
export const KEY_UP = 'ArrowUp';
export const KEY_LEFT = 'ArrowLeft';
export const KEY_RIGHT = 'ArrowRight';
export const KEY_SHIFT = 'Shift';
export const KEY_CAPS = 'CapsLock';
export const KEY_META = 'Meta';
export const KEY_CTRL = 'Control';
export const KEY_ALT = 'Alt';
export const KEY_TAB = 'Tab';


export const SERVICE_UNAVAILABLE = 503;
export const SERVICE_UNAVAILABLE_MSG = 'Service unavailable. Please try again later.';

export const INTERNAL_SERVER_ERROR = 500;
export const INTERNAL_ERROR_MSG = 'Something went wrong internally. IT support has been contacted.';


export const ALL_CATEGORY = 'all';
export const DEFAULT_TAX_RATE = 0.1;
