/**
 * Created by Karl on 13/02/2017.
 */
import {Injectable} from '@angular/core';
import {Product, ProductFormData} from '../../shared/models/product';


import {Observable, of} from 'rxjs'
import {GAPI} from '../../gapi';
import {AdminService} from '../../admin/admin.service';
import {HttpClient} from '@angular/common/http';
import {CatalogueInfoResponse, ProductSearchResponse} from '../product';
import {environment} from '../../../environments/environment';
import {buildUrl, getAuthHeaders} from '../../utils';
import {MessageResponse} from '../../index';
import {mergeMap, switchMap, map, tap, first} from 'rxjs/operators';


export function appendToFormIfValueExists(form: FormData, key: string, value: any) {
    if (value !== null) {
        form.append(key, value);
    }
}

@Injectable()
export class ProductService {

    constructor(private http: HttpClient, private adminService: AdminService) {
    }

    isBarcodeAvailable(barcode: string): Observable<boolean> {
        if (!barcode || !barcode.trim()) {
            return of(null);
        }
        barcode = barcode.trim();
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.isBarcodeAvailableFromServer(token, barcode)),
            map(response => response.success),
            first()
        );
    }

    private isBarcodeAvailableFromServer(token: string, barcode: string): Observable<MessageResponse> {
        const url = buildUrl(environment.hostUrl, ['is_barcode_available'], {barcode: barcode});
        return this.http.get<MessageResponse>(url, {headers: getAuthHeaders(token)});
    }

    getProduct(websafeKey: string): Observable<Product> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.getProductFromServer(token, websafeKey)),
            tap(response => console.log('DEBUG', 'getProductFromServer', response)),
            first()
        );
    }

    private getProductFromServer(token: string, websafeKey: string): Observable<Product> {
        const url: string = GAPI.buildApiPath('get_product', websafeKey);
        return this.http.get<Product>(url, {headers: getAuthHeaders(token)});
    }


    searchProducts(searchText = '', offset = 0, pageSize = 25): Observable<ProductSearchResponse> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.searchProductsOnServer(token, searchText, offset, pageSize)),
            first()
        )
    }

    private searchProductsOnServer(token: string, searchText: string, offset: number, pageSize: number): Observable<ProductSearchResponse> {
        const url = buildUrl(environment.hostUrl, ['search_vendor_products'], {});
        const params = {searchText: searchText, currentOffset: offset, pageSize: pageSize};
        console.log('SEARCH: ', params);
        return this.http.post<ProductSearchResponse>(url, params, {headers: getAuthHeaders(token)});

    }

    getCatalogueInfo(): Observable<CatalogueInfoResponse> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.getCatalogueInfoFromServer(token)),
            first()
        );
    }

    private getCatalogueInfoFromServer(token: string): Observable<CatalogueInfoResponse> {
        const url = buildUrl(environment.hostUrl, ['get_catalogue_info'], {});
        return this.http.get<CatalogueInfoResponse>(url, {headers: getAuthHeaders(token)});
    }

    createProduct(data: ProductFormData): Observable<Product> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.createProductOnServer(token, data)),
            first()
        );
    }

    private createProductOnServer(token: string, product: ProductFormData): Observable<Product> {
        const url = buildUrl(environment.hostUrl, ['create_product'], {});
        const form = new FormData();
        appendToFormIfValueExists(form, 'name', product.name);
        appendToFormIfValueExists(form, 'description', product.description);
        appendToFormIfValueExists(form, 'price', product.price);
        appendToFormIfValueExists(form, 'taxRate', product.taxRate);
        appendToFormIfValueExists(form, 'barcode', product.newBarcode);
        appendToFormIfValueExists(form, 'photo', product.photo);
        return this.http.post<Product>(url, form, {headers: getAuthHeaders(token)});
    }


    updateProduct(data: ProductFormData) {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.updateProductOnServer(token, data)),
            first()
        )
    }

    private updateProductOnServer(token: string, product: ProductFormData): Observable<Product> {
        const url = buildUrl(environment.hostUrl, ['update_product'], {});
        const form = new FormData();
        console.log('DEBUG', 'UPDATE PRODUCT', product);
        appendToFormIfValueExists(form, 'name', product.name);
        appendToFormIfValueExists(form, 'description', product.description);
        appendToFormIfValueExists(form, 'price', product.price);
        appendToFormIfValueExists(form, 'photo', product.photo);
        appendToFormIfValueExists(form, 'taxRate', product.taxRate);
        appendToFormIfValueExists(form, 'websafeKey', product.websafeKey);

        // Append barcode if it has changed from previous barcode.
        if (product.newBarcode !== product.barcode) {
            appendToFormIfValueExists(form, 'barcode', product.newBarcode);
        }

        return this.http.post<Product>(url, form, {headers: getAuthHeaders(token)});
    }

    deleteProduct(websafeKey: string): Observable<MessageResponse> {
        return this.adminService.getIdToken().pipe(
            switchMap(token => this.deleteProductOnServer(token, websafeKey)),
            first()
        );
    }

    private deleteProductOnServer(token: string, websafeKey: string): Observable<MessageResponse> {
        const url = buildUrl(environment.hostUrl, ['delete_product'], {websafeKey: websafeKey});
        return this.http.delete<MessageResponse>(url, {headers: AdminService.getAuthHttpHeaders(token)});
    }
}
