import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-not-setup',
  templateUrl: './vendor-not-setup.component.html',
  styleUrls: ['./vendor-not-setup.component.css']
})
export class VendorNotSetupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
