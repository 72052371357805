/**
 * Created by Karl on 30/04/2017.
 */


import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AdminService} from '../admin/admin.service'
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';


@Injectable()
export class VendorSetupGuard implements CanActivate {

    constructor(private adminService: AdminService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.adminService.vendorInfo$.pipe(
            switchMap(([user, vendorAccount]) => {
                if (!!user && !vendorAccount) {
                    return combineLatest(of(user), this.adminService.getVendorAccount())
                }
                return combineLatest(of(user), of(vendorAccount));
            }),
            map(([user, vendorAccount]) => {
                    if (!user) {
                        this.router.navigate(['login']);
                        return false;
                    }

                    if (!user.emailVerified) {
                        this.router.navigate(['setup', 'verify-email']);
                        return false;
                    }

                    if (!vendorAccount) {
                        this.router.navigate(['vendor-not-setup']);
                        return false;
                    }

                    if (vendorAccount.requiresReset) {
                        this.router.navigate(['reset-password'], {queryParams: {required: true}});
                        return false;
                    }

                    return true;
                }
            )
        );
    }
}
