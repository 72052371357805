import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {ReportingService} from '../reporting.service';
import {AdminService} from '../admin/admin.service';
import {HttpErrorResponse} from '@angular/common/http';
import {INTERNAL_ERROR_MSG, INTERNAL_SERVER_ERROR, SERVICE_UNAVAILABLE, SERVICE_UNAVAILABLE_MSG} from '../constants';

const SMALL_SCREEN = 768;

@Component({
    selector: 'app-report-generator',
    templateUrl: './report-generator.component.html',
    styleUrls: ['./report-generator.component.css']
})
export class ReportGeneratorComponent implements OnInit, AfterViewInit {

    screenSize = null;

    hoveredDate: NgbDate;

    fromDate: NgbDate;
    toDate: NgbDate;

    isDownloading = false;
    errorMessage: string;

    constructor(calendar: NgbCalendar, private adminService: AdminService, private reportingService: ReportingService) {
    }

    ngOnInit() {
        this.screenSize = window.innerWidth;
    }

    ngAfterViewInit() {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenSize = event.target.innerWidth;
    }

    get isSmallDevice() {
        return this.screenSize < SMALL_SCREEN;
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        if (!date) {
            return false;
        }
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        if (!date) {
            return false;
        }
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    generateReport() {
        this.isDownloading = true;
        this.errorMessage = null;
        const reportName = this.getReportName(this.fromDate, this.toDate);
        this.reportingService.generateReport(this.fromDate, this.toDate).subscribe(
            report => this.downloadReport(reportName, report.contentType, report.content),
            error => this.handleError(error)
        );
    }

    getReportName(fromDate: NgbDate, toDate: NgbDate, ext='.xlsx') {
        const vendorAccount = this.adminService.vendorAccount;
        if (vendorAccount == null) {
            return '';
        }
        const vendorName = vendorAccount!.vendor!.name!.replace(/\s/g, '_') || '';
        const fromDateStr = this.toDateStr(fromDate);
        const toDateStr = this.toDateStr(toDate);
        if (!fromDateStr && !toDateStr) {
            return  vendorName + ext;
        }

        if (!fromDateStr) {
            throw new Error('Must have a from date but not necessarily a to date.')
        }

        if (!toDateStr) {
            return fromDateStr + '-' + vendorName + ext;
        }

        return fromDateStr + '-' + toDateStr + '-' + vendorName + ext
    }


    toDateStr(date: NgbDate) {
        if(!date) {
            return '';
        }
        const paddedMonth = `${date.month}`.padStart(2, '0');
        const paddedDay = `${date.day}`.padStart(2, '0');

        return `${date.year}${paddedMonth}${paddedDay}`;
    }

    downloadReport(reportName: string, contentType: string, arrayBuffer: any) {
        this.isDownloading = false;
        const blob = new Blob([arrayBuffer], {type: contentType});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = reportName;
        link.click();
    }

    handleError(error: any) {
        this.isDownloading = false;
        if (error instanceof HttpErrorResponse) {
            this.handleHttpError(<HttpErrorResponse>error);
            return;
        }

        this.errorMessage = SERVICE_UNAVAILABLE_MSG;

    }

    private handleHttpError(error: HttpErrorResponse) {
        if (error.status === SERVICE_UNAVAILABLE) {
            this.errorMessage = SERVICE_UNAVAILABLE_MSG;
            return;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            this.errorMessage = INTERNAL_ERROR_MSG;
            return;
        }
        console.log(error);
        this.errorMessage = error!.error!.error!.message || SERVICE_UNAVAILABLE_MSG;
    }
}
