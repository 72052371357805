/**
 * Created by Karl on 13/02/2017.
 */
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {AppComponent} from './app.component'
import {HttpModule} from "@angular/http";
import {ProductService} from "./product/services/product.service";
import {AppRoutingModule} from "./app-routing-module";
import {ProductModule} from "./product/product.module";
import {AngularFireModule} from "angularfire2";
import {AdminService} from "./admin/admin.service";
import {Router} from "@angular/router";
import {AdminModule} from "./admin/admin.module";
import {AngularFireAuthModule} from "angularfire2/auth";
import {environment} from "../environments/environment";
import {NgbDateAdapter, NgbModalModule, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {DashboardModule} from "./dashboard/dashboard.module";
import {StoreModule} from "./store/store.module";
import {OffersModule} from "./offers/offer.module";
import {HttpClientModule} from "@angular/common/http";
import {NgbDateNativeAdapter} from "./shared/adapters/date.adapter";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {LoginComponent} from './login/login.component';
import {SharedModule} from './shared/shared.module';
import { ApiDocsComponent } from './documentation/api-docs/api-docs.component';
import {ScrollSpyDirective} from './scroll-spy.directive';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    // AppRoutingModule always has to be last
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        FontAwesomeModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModalModule,
        NgbDatepickerModule,
        SharedModule,
        DashboardModule,
        ProductModule,
        OffersModule,
        StoreModule,
        AdminModule,
        AppRoutingModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        ResetPasswordComponent,
        ApiDocsComponent,
        ScrollSpyDirective
    ],
    providers: [
        {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
        ProductService,
        AdminService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(router: Router) {
        console.log('Environment', environment);
    }
}
