import {Observable, of, from, combineLatest} from 'rxjs'
import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {GAPI} from '../gapi';
import {AngularFireAuth} from 'angularfire2/auth';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {buildUrl, getAuthHeaders} from '../utils';
import {MessageResponse, VendorAccount} from '../index';
import {environment} from '../../environments/environment'
import {map, tap, switchMap, filter, first} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs/Rx';


/**
 * Created by Karl on 19/04/2017.
 */
@Injectable({
    providedIn: 'root'
})
export class AdminService {

    vendorAccount: VendorAccount;

    isLoggedIn$: Observable<boolean> = this.af.user.pipe(
        map(user => !!user),
    );

    currentUser$: Observable<firebase.User> = this.af.user.pipe(
        filter(user => user != null )
    );

    vendorAccount$: Subject<VendorAccount> = new BehaviorSubject<VendorAccount>(null);
    vendorInfo$ = combineLatest(this.af.user, this.vendorAccount$.asObservable());

    isLoggedInAndSetup$ = this.vendorInfo$.pipe(
        map(([user, vendorAccount]) => !!user && vendorAccount != null && !vendorAccount.requiresReset),
    );

    constructor(private af: AngularFireAuth, private http: HttpClient, private router: Router) {
    }

    createUser(user: any): Observable<any> {
        return from(this.createUserAndProfile(user))
    }

    private createUserAndProfile(user: any) {
        return this.af.auth.createUserWithEmailAndPassword(user.email, user.password)
            .then(() => this.updateFirebaseUserInfo(user.firstName, user.lastName))
            .then(() => this.sendEmailVerificationByFirebase())
            .catch(error => console.log('ERROR', error));
    }

    updateUserInfo(firstName: string, lastName: string, photoUrl?: string) {
        return from(this.updateFirebaseUserInfo(firstName, lastName, photoUrl));
    }

    private updateFirebaseUserInfo(firstName: string, lastName: string, photoUrl?: string) {
        let displayName: string = this.createDisplayName(firstName, lastName);
        return this.af.auth.currentUser
            .updateProfile({displayName: displayName, photoURL: photoUrl})
            .then(() => console.log('DEBUG', 'Updated profile', 'Display Name:', displayName, 'Photo:', photoUrl))
            .catch(error => console.log('ERROR', error));
    }

    private createDisplayName(firstName: string, lastName: string) {
        firstName = firstName || '';
        lastName = lastName || '';

        if (firstName && lastName) {
            return firstName + ' ' + lastName;
        }

        return firstName ? firstName : lastName
    }

    sendEmailVerification(): Observable<void> {
        return from(this.sendEmailVerificationByFirebase());
    }

    private sendEmailVerificationByFirebase() {
        return this.af.auth!.currentUser!.sendEmailVerification()
            .then(() => console.log('DEBUG', 'Sent Email Verification'))
            .catch(error => console.log('ERROR', 'Could not send email verification'));
    }

    loginWithEmailAndPassword(email: string, password: string): Observable<any> {
        return from(this.signInWithEmailAndPassword(email, password))
    }

    private signInWithEmailAndPassword(email: string, password: string) {
        return this.af.auth.signInWithEmailAndPassword(email, password)
    }


    resetPassword(password: string, confirmPassword: string) {
        return this.getIdToken().pipe(
            switchMap(token => this.resetPasswordOnServer(token, password, confirmPassword)),
            tap( response => {
                if(!!response && response.success) {
                    this.vendorAccount.requiresReset = false;
                    this.vendorAccount$.next(this.vendorAccount);
                }
            })
        )
    }

    private resetPasswordOnServer(token: string, password: string, confirmPassword: string): Observable<MessageResponse> {
        const url = buildUrl(environment.hostUrl, ['reset_password'], {});
        const params = {password: password, confirmPassword: confirmPassword}
        return this.http.post<MessageResponse>(url, params, {headers: getAuthHeaders(token)}).pipe(
            tap( response => {
                if (!!response && response.success && this.vendorAccount) {
                    this.vendorAccount!.requiresReset = false;
                }
            })
        )
    }

    getIdToken(forceReload?: boolean): Observable<string> {
        return this.af.user.pipe(
            first(),
            switchMap(user => {
                if (!user) {
                    return of(null);
                }
                return user.getIdToken(!!forceReload);
            })
        );
    }

    private reloadUser(user: firebase.User): Observable<firebase.User | null> {
        if (!user) {
            return of(null);
        }

        return from(user.reload()).pipe(
                switchMap(result => this.af.authState)
        );
    }

    isLoggedIn(): Observable<any> {
        return this.af.user.pipe(map(user => this.toUserLoginResult(user)));
    }

    toUserLoginResult(user: firebase.User) {
        return {
            'user': user,
            'isLoggedIn': !!user,
            'isLoggedInAndEmailVerified': user && user.emailVerified
        };
    }


    isLoggedInAndVendorSetup(): Observable<any> {
        return this.isLoggedIn().pipe(
            switchMap(loggedInResult => this.isVendorSetup(loggedInResult)),
        );
    }


    isVendorSetup(loggedInResult: any): Observable<any> {

        if (!loggedInResult.isLoggedInAndEmailVerified) {
           return of(this.toSetupAccountResult(loggedInResult, {}));
        }

        console.log('isVendorSetup, Calling get vendor account');
        return this.getVendorAccount().pipe(
            map(account => this.toSetupAccountResult(loggedInResult, account))
        );
    }

    private toSetupAccountResult(loggedInResult: any, vendorAccount: any): any {
        loggedInResult['isVendorSetup'] = loggedInResult && vendorAccount && ('accountType' in vendorAccount);
        loggedInResult['vendorAccount'] = vendorAccount;
        return loggedInResult;
    }


    logout(): Observable<any> {
        return from(this.af.auth.signOut()).pipe(
            tap(_ => console.log('Signed out', _)),
            tap( _ => this.vendorAccount = null),
            tap( _ => this.vendorAccount$.next(null))
        );
    }


    deleteAccount() {
        return this.getIdToken().pipe(
            switchMap(token => this.deleteAccountOnServer(token)),
            tap(result => this.logout())
        );
    }

    private deleteAccountOnServer(token: string) {
        let url = GAPI.buildApiPath('delete_user');
        return this.http.delete(url, {headers: getAuthHeaders(token)})
    }

    getVendorAccount(forceReload?: boolean): Observable<VendorAccount> {
        console.log('GET VENDOR ACCOUNT getVendorAccount ', forceReload, !forceReload, this.vendorAccount, this.vendorAccount != null);
        return !forceReload && this.vendorAccount != null
            ? this.vendorAccount$.asObservable()
            : this.getIdToken().pipe(
                switchMap(token => this.getVendorAccountFromServer(token)),
            );

    }

    private getVendorAccountFromServer(token: string): Observable<VendorAccount> {
        if (!token) {
            return of(null);
        }
        console.log('get Vendor account from server called');
        const url = buildUrl(environment.hostUrl, ['get_vendor_account'], {});
        return this.http.get<VendorAccount>(url, {headers: getAuthHeaders(token)}).pipe(
            tap(vendorAccount => {
                if (!!vendorAccount) {
                    this.vendorAccount = vendorAccount;
                    this.vendorAccount$.next(vendorAccount)
                }
            })
        );
    }



    updateVendorProfile(data: any): Observable<any> {
        const form: FormData = new FormData();
        form.append('logo', data.logo);
        return this.getIdToken().pipe(
            switchMap(token => this.updateVendorProfileOnServer(token, form))
        );
    }

    private updateVendorProfileOnServer(token: string, form: FormData): Observable<any> {
        const url = GAPI.buildApiPath('update_vendor_profile');
        return this.http.post(url, form, {headers: getAuthHeaders(token)});
    }

    verifyInvite(pendingUserKey: string): Observable<any> {
        const url = GAPI.buildApiPath('verify_invite');
        return this.http.post(url, {websafeKey: pendingUserKey})
    }

    convertPendingUser(user: any): Observable<any> {
        let url: string = GAPI.buildApiPath('convert_pending_user');
        return this.http.post(url, user).pipe(
            switchMap(response => this.loginWithEmailAndPassword(user.email, user.password))
        );
    }


    static getAuthHttpHeaders(authToken?: string): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders();
        if (authToken) {
            headers = headers.append('Authorization', 'Bearer ' + authToken);
        }
        return headers;
    }

    inviteUser(email: string): Observable<any> {
        let url = GAPI.buildApiPath('invite_user');
        return this.getIdToken().pipe(
            switchMap(token => this.postInviteUser(token, email))
        );
    }

    private postInviteUser(token: string, email: string) {
        const url = GAPI.buildApiPath('invite_user');
        return this.http.post(url, {email: email}, {headers: getAuthHeaders(token)});
    }


    public getLogoUrl() {
        return this!.vendorAccount!.vendor!.profile!.logoUrl;
    }
}
