import {NgModule} from "@angular/core";
import {StoreDashboardComponent} from "./dashboard/store-dashboard.component";
import {StoreRoutingModule} from "./store-routing.module";
import {environment} from "../../environments/environment";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreService} from "./store.service";
import { StoreOverviewComponent } from './store-overview/store-overview.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { StoreComponent } from './store/store.component';
import {AgmCoreModule} from '@agm/core';
import { NewStoreComponent } from './new-store/new-store.component';
import { StoreControlsComponent } from './store-controls/store-controls.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module';
import { DeleteStoreComponent } from './delete-store/delete-store.component';


/**
 * Created by Karl on 6/9/17.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        StoreRoutingModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModalModule,
        SharedModule,
        AgmCoreModule.forRoot({
            apiKey: environment.gmapsKey,
            libraries: ['geometry', 'places']
        })
    ],
    declarations: [
        StoreDashboardComponent,
        StoreOverviewComponent,
        StoreComponent,
        NewStoreComponent,
        StoreControlsComponent,
        DeleteStoreComponent,
    ],
    providers: [StoreService],
    entryComponents: [
        NewStoreComponent,
        DeleteStoreComponent
    ]
})
export class StoreModule {}
