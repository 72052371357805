/**
 * Created by Karl on 25/8/17.
 */
import {Component} from "@angular/core";
import {AdminService} from "../../admin.service";
import {Router} from "@angular/router";
import 'rxjs-compat/add/operator/partition';


@Component({
    moduleId: module.id,
    selector: 'verify-email',
    template: `
    <div class="row">
        <div class="col-md-12">
            <h1>Email Not Verified</h1>
            <p>Please check your email and follow the instructions to verify your account.</p>
            <p>Wait 5 minutes for the account to be verified.</p>
            <p *ngIf="error">{{error}}</p>
            <p>If you didn't receive the email or the link has expired click below to resend the email</p>
            <p *ngIf="emailResent">You're email has been resent!</p>
            <div class="row">
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="resendEmailVerification()">Resend email</button>  
                </div>
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="continueSetup()">I've verified my account. Continue</button>  
                </div>
            </div>
        </div>
    </div>`,
    styles: [`.text-red { color: #c23321; }`]
})
export class VerifyEmailComponent {

    error: string;
    emailResent: boolean = false;


    constructor(private adminService: AdminService, private router: Router) {

    }

    resendEmailVerification() {
        this.adminService.sendEmailVerification()
            .subscribe( result => {
            this.emailResent = true;
        });
    }

    continueSetup() {
        const jwtPaylotPart = this.adminService.getIdToken( true )
            .map( jwt => this.parseJWT( jwt ) )
            .partition( payload => payload && payload['email_verified'])

        jwtPaylotPart[0].subscribe( token => {
            console.log( 'DEBUG', 'Refreshed Token', token );
            this.router.navigate(['setup', 'form-business'])
        });
        jwtPaylotPart[1].subscribe( notVerified =>
            this.error = 'You are not verified. Please check your email again or contact help-desk.');
    }


    parseJWT(jwt: string) {
        if ( !jwt ) {
            return {};
        }
        console.log( 'DEBUG', 'Parsing JWT', JSON.parse(atob(jwt.split('.')[1])));
        return JSON.parse(atob(jwt.split('.')[1]))
    }

}
