/**
 * Created by Karl on 12/3/18.
 */
import {NgModule} from "@angular/core";
import {OffersRoutingModule} from "./offer-routing.module";
import {OffersDashboardComponent} from "./dashboard/offer-dashboard.component";
import {BrowserModule} from "@angular/platform-browser";
import {OfferComponent} from "./offer/offer.component";
import {CreateOfferComponent} from "./create/create-offer.component";
import {OffersListComponent} from "./list/offers-list.component";
import {FormsModule} from "@angular/forms";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {OffersService} from "./services/offers.service";
import {EndDateValidatorDirective} from "../shared/directives/end-date-validator.directive";

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        OffersRoutingModule,
        NgbDatepickerModule,
    ],
    declarations: [
        OfferComponent,
        OffersListComponent,
        CreateOfferComponent,
        OffersDashboardComponent,
        EndDateValidatorDirective
    ],
    providers: [
        OffersService
    ]
})
export class OffersModule {}
