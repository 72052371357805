/// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts" />
import {MapsAPILoader} from '@agm/core';
/**
 * Created by Karl on 6/9/17.
 */

import {AfterViewInit, Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs/Rx';
import {catchError, debounceTime, switchMap, throttleTime} from 'rxjs/operators';
import {StoreService} from '../store.service';
import {of} from 'rxjs';
import {
    INTERNAL_ERROR_MSG,
    INTERNAL_SERVER_ERROR,
    SERVICE_UNAVAILABLE,
    SERVICE_UNAVAILABLE_MSG
} from '../../utils'
import {HttpErrorResponse} from '@angular/common/http';
import {Store, StorePageResponse} from '../index';
import {NewStoreComponent} from '../new-store/new-store.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {tap} from 'rxjs/operators';


export const SELECTED_STORE_ICON = './assets/imgs/store-icon-selected.png';
export const INACTIVE_STORE_ICON = './assets/imgs/store-icon-inactive.png';
export const STORE_ICON = './assets/imgs/store-icon.png';
export const BUFFER_MULTIPLIER = 1.1;

@Component({
    selector: 'app-dashboard',
    templateUrl: 'store-dashboard.component.html',
    styleUrls: ['./store-dashboard.component.css']
})
export class StoreDashboardComponent implements OnInit, AfterViewInit {

    bounds: google.maps.LatLngBounds;
    selectedCenter: google.maps.LatLng;
    center: google.maps.LatLng;
    cornerDist: number;
    zoom: number = 13;

    private searchStoresSubject: Subject<any> = new Subject<any>();
    private searchSub: Subscription;

    // Paging requirements
    currentOffset: number = 25;
    totalStores: number;
    stores: Store[] = [];
    pageSize: number = 25;
    pageLoading: boolean;

    selectedStore: Store;

    errorMessage: string;

    @ViewChild('map')
    map;

    @ViewChildren('marker')
    markers;

    @ViewChild('overview')
    overview;

    constructor(private mapsAPILoader: MapsAPILoader,
                private storesService: StoreService,
                private modalService: NgbModal) {
        this.mapsAPILoader.load().then(() => {
            this.setCurrentPosition();
        })
    }

    ngOnInit(): void {
        this.searchSub = this.searchStoresSubject.pipe(
            tap( _ => this.pageLoading = true),
            debounceTime(150),
            tap( params => {
                if (!!params.refresh) {
                    this.stores = []
                }
                console.log('DEBUG', 'current-offset', params.offset);
            }),
            switchMap(params => this.storesService.getStoresPage(params.center, params.offset, this.pageSize, params.refresh)
                .pipe( catchError(error  => this.handleError(error)))
            ),
            tap( _ => this.pageLoading = false)
        ).subscribe( response => this.onUpdateStorePage(response));
    }

    ngAfterViewInit(): void {
    }

    onSelectedCenter(center:  google.maps.LatLng) {
        this.selectedCenter = new google.maps.LatLng(center.lat(), center.lng());
        this.center = new google.maps.LatLng(center.lat(), center.lng());
        this.selectedStore = null;

        // Reset to first page when manually changing the center
        this.currentOffset = 0;
        this.onSearchChanged(this.currentOffset, true);
    }

    onCenterChange(center: google.maps.LatLngLiteral | google.maps.LatLng) {
        console.log('onCenterChanged', center);
        if ( center instanceof google.maps.LatLng ) {
            this.center = new google.maps.LatLng(center.lat(), center.lng());
            return;
        }

        this.center = new google.maps.LatLng(center.lat, center.lng);
        // this.onSearchChanged(false);
    }

    setCurrentPosition() {
        if (!navigator || !navigator.geolocation) {
            return;
        }

        navigator.geolocation.getCurrentPosition(
            position => this.onPositionSuccess(position),
            error => this.onPositionError(error)
        );
    }

    onPositionSuccess(position: Position) {
        this.selectedCenter = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        this.center = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        this.currentOffset = 0;
        this.onSearchChanged(this.currentOffset, true);
    }

    onPositionError(error: PositionError) {
        console.log('DEBUG', 'Position error', error);
    }


    onBoundsChanged(bounds: google.maps.LatLngBounds) {
        console.log('DEBUG', 'onBoundsChanged');
        this.bounds = bounds;
        // this.setCornerDist();
    }

    setCornerDist() {
        console.log('DEBUG', 'setCornerDist');
        if ( !this.center || !this.bounds ) {
            return;
        }


        console.log(this.bounds, this.center);
        this.cornerDist = google.maps.geometry.spherical.computeDistanceBetween(
            this.bounds.getNorthEast(),
            this.center
        ) * BUFFER_MULTIPLIER;

    }

    onZoomChanged(zoom: number) {
        console.log('ZOOM changed', zoom);
        this.zoom = zoom;
        this.setCornerDist();
        this.onSearchChanged(this.currentOffset, false);
    }

    onPageSelected(page: number) {
        const offset = Math.max(0, (page - 1) * this.pageSize);
        this.currentOffset = offset;
        this.onSearchChanged(offset, true)
    }

    private getHttpErrorMessage(error: HttpErrorResponse) {
        if (error.status === SERVICE_UNAVAILABLE) {
            return SERVICE_UNAVAILABLE_MSG;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            return INTERNAL_ERROR_MSG;
        }

        const clientError = error!.error!.error!.message;
        return clientError == null ? SERVICE_UNAVAILABLE_MSG : clientError;
    }


    handleError(error): Observable<StorePageResponse> {
        if (error instanceof HttpErrorResponse) {
            this.errorMessage = this.getHttpErrorMessage(error);
        } else {
            this.errorMessage = SERVICE_UNAVAILABLE_MSG;
        }

        return of({stores: [], total: 0, refresh: false});

    }

    onSearchChanged(offset: number, forceRefresh: boolean) {
        if ( this.center == null ) {
            return;
        }

        const params = {
            center: this.selectedCenter,
            offset: offset,
            refresh: forceRefresh
        };
        this.searchStoresSubject.next(params);
    }

    onUpdateStorePage(response: StorePageResponse) {
        if (response == null) {
            return;
        }

        this.totalStores = response.total;

        const stores = response.stores || [];
        const forceRefresh = !!response.refresh;
        if (forceRefresh) {
            this.stores = stores;
            return;
        }

        const storeIds = new Set(this.stores.map(store => store.websafeKey))
        const storesToAdd = stores.filter(store => !storeIds.has(store.websafeKey))
        storesToAdd.forEach( store => this.stores.push(store));
    }

    onSelectStore(store: Store) {
        this.selectedStore = store;
    }

    isSelected(store: Store) {
        return this.selectedStore && this.selectedStore.websafeKey == store.websafeKey;
    }

    getIconUrl(store: Store) {
        if (!store.active) {
            return INACTIVE_STORE_ICON;
        }
        return this.isSelected(store) ? SELECTED_STORE_ICON : STORE_ICON;
    }

    onNewStore(searchStr: string) {
        const modalRef = this.modalService.open(NewStoreComponent, {backdrop: 'static', keyboard: false});
        modalRef.result.then( result => {
            if (typeof result === 'boolean' && result) {
                this.onSearchChanged(this.currentOffset, true);
                return;
            }

            if (result.lat != null && result.lng != null) {
                this.currentOffset = 0;
                this.stores = [result];
                this.selectedCenter = new google.maps.LatLng(result.lat, result.lng);
                this.center = new google.maps.LatLng(result.lat, result.lng);
                this.selectedStore = result;
                this.onSearchChanged(this.currentOffset, false);
            }
        })
    }

    onStoreDeleted() {
        this.stores = [];
        this.onSearchChanged(this.currentOffset, true);
    }

}
