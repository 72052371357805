/**
 * Created by Karl on 12/3/18.
 */

import {Component, Input, Output, EventEmitter} from "@angular/core";
import {Offer} from "../offer";

@Component({
    moduleId: module.id,
    selector: 'offer',
    template:  `
        <div class="row">
            <div class="col-2">
                <img width="200px" height="auto" src="{{offer.imageUrl}}" >
            </div>
            <div class="col-10">
                <div class="row">
                    <div class="col-12">
                        <h2 class="inline-title">{{offer.title}}</h2>
                        <button (click)="emitDelete()" class="btn btn-sm delete-button ">x</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>{{offer.body}}</p>
                        <p>{{offer.startDate | date: 'dd/MM/yyyy'}} - {{offer.endDate | date: 'dd/MM/yyyy'}}</p>    
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./offer.component.css']
})
export class OfferComponent {

    @Input() offer: Offer;
    @Output() deleteOffer: EventEmitter<any> = new EventEmitter<any>();

    emitDelete() {
        this.deleteOffer.emit( null );
    }
}
