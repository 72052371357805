import {HttpClient} from "@angular/common/http";
import {AdminService} from "../../admin/admin.service";
import {Observable} from "rxjs";
import "rxjs/operators/first"
import {Injectable} from "@angular/core";
import {Offer, OfferForm, OffersResponse} from "../offer";
import {GAPI} from "../../gapi";

/**
 *
 * Created by Karl on 13/3/18.
 */
@Injectable()
export class OffersService {

    constructor(private adminService: AdminService, private http: HttpClient) {
    }

    getOffers(): Observable<Offer[]> {
        return this.adminService.getIdToken(false).flatMap(token => this.getOffersFromServer(token)).first()
    }

    private getOffersFromServer(token: string): Observable<Offer[]> {
        const url = GAPI.buildApiPath('get_offers');
        return this.http
            .get<OffersResponse>(url, {headers: AdminService.getAuthHttpHeaders(token)})
            .map(response => response.offers || []);
    }

    createOffer(data: OfferForm): Observable<Offer[]> {
        return this.adminService.getIdToken(false)
            .flatMap(token => this.createOfferFromServer(token, data))
            .first();
    }

    private createOfferFromServer(token: string, data: OfferForm): Observable<Offer[]> {
        const url = GAPI.buildApiPath("create_offer");
        const form: FormData = new FormData();
        form.append('title', data.title);
        form.append('body', data.body);
        form.append('image', data.image);
        form.append('startDate', data.startDate.toISOString().replace('Z', ''));
        form.append('endDate', data.endDate.toISOString().replace('Z', ''));
        return this.http
            .post<OffersResponse>(url, form, {headers: AdminService.getAuthHttpHeaders(token)})
            .map(response => response.offers || []);
    }

    deleteOffer(offerKey: string): Observable<any> {
        return this.adminService.getIdToken(false)
            .flatMap(token => this.deleteOfferFromServer(token, offerKey))
            .first();
    }

    private deleteOfferFromServer(token: string, offerKey: string) {
        const url = GAPI.buildApiPath('delete_offer');
        const urlWithQuery = GAPI.addQueries(url, {websafeKey: offerKey});
        return this.http.delete(urlWithQuery, {headers: AdminService.getAuthHttpHeaders(token)});
    }
}
