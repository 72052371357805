import {Component} from "@angular/core";
import {AdminService} from "./admin.service";
import {Router} from "@angular/router";
import {GAPI} from "../gapi";
/**
 * Created by Karl on 1/9/17.
 */

@Component({
    moduleId: module.id,
    selector: `admin`,
    template: `
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <admin-invite-user></admin-invite-user>
                </div>
            </div>
            <div class="row justify-content-center padding-top-10">
                <div class="col-md-4">
                    <button (click)="deleteAccount()" class="btn btn-danger">Delete Account</button>
                </div>
            </div>
            <div class="row justify-content-center padding-top-10">
                <div class="col-md-4">
                    TODO Manage Users Component.
                </div>
            </div>
            
            <div class="row justify-content-center padding-top-10">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="upload-product-image">Update Logo</label><br>
                        <img draggable="true" [src]="logoUrl" width="160px" height="120px">
                        <input (change)="onChangePhoto($event)" class="form-control-file" id="upload-product-image" type="file" name="file">
                    </div>
                    <button class="btn btn-primary"(click)="updateProfile()">Update Profile</button>
                </div>
            </div>
        </div>
    `
})
export class AdminComponent {

    logoPhoto = "";
    logoUrl = "";
    constructor(private adminService: AdminService, private router: Router) {
        this.logoUrl = this.adminService.getLogoUrl();
    }

    onChangePhoto(event: any) {
        let files = event.srcElement.files;
        this.logoPhoto = files[0];
        console.log('DEBUG', 'logo photo', this.logoPhoto);
        let fr: FileReader = new FileReader();
        let form: AdminComponent = this;
        fr.onloadend = function () {
            form.logoUrl =  <string> fr.result;
        };
        fr.readAsDataURL(files[0]);
    }

    updateProfile() {
        if (this.logoPhoto == null) {
            return;
        }

        this.adminService.updateVendorProfile({'logo': this.logoPhoto}).subscribe( result => {
            console.log('DEBUG', result);
            this.refreshVendorAccount();
        })
    }

    refreshVendorAccount() {
        console.log('refreshVendorAccount, Calling get vendor account');
        this.adminService.getVendorAccount( true )
            .subscribe( result => {
                console.log( 'Successfully Refreshed Vendor', result );
                this.logoUrl = this.adminService.getLogoUrl();
            } );
    }

    deleteAccount() {
        console.log('DEBUG', 'SENT DELETE');
        this.adminService.deleteAccount().subscribe(result => {
            console.log('DEBUG', 'RESULT', result);
            this.router.navigate(['login'])
        });
    }
}
