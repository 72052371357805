/**
 * Created by Karl on 16/02/2017.
 */
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms'
import {CommonModule} from '@angular/common';
import {ProductDashboardComponent} from './dashboard/product-dashboard.component';
import {ProductFormComponent} from './form/product-form.component';
import {BarcodeValidatorDirective} from './directives/barcode-validator.directive';
import {RangeValidatorDirective} from '../shared/directives/range-validator.directive';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ProductComponent} from './product/product.component';
import {SharedModule} from '../shared/shared.module';
import {ProductsOverviewComponent} from './products-overview/products-overview.component';
import {ProductPreviewComponent} from './product-preview/product-preview.component';
import {PriceValidatorDirective} from './directives/price-validator.directive';
import {TaxValidatorDirective} from './directives/tax-validator.directive';
import {VendorSetupGuard} from '../vendor-setup-guard/vendor-setup.guard';
import {RouterModule, Routes} from '@angular/router';


const routes: Routes = [
    {path: 'products', component: ProductDashboardComponent, canActivate: [VendorSetupGuard]},
    {path: 'product', component: ProductFormComponent, canActivate: [VendorSetupGuard]},
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        FontAwesomeModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        ProductDashboardComponent,
        ProductFormComponent,
        BarcodeValidatorDirective,
        RangeValidatorDirective,
        ProductComponent,
        ProductsOverviewComponent,
        ProductPreviewComponent,
        PriceValidatorDirective,
        TaxValidatorDirective,
    ],
    providers: [VendorSetupGuard]
})
export class ProductModule {
}
