/**
 * Created by Karl on 16/02/2017.
 */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Product} from '../../shared/models/product';
import {ProductService} from '../services/product.service';
import {faPlus, faSearch, faBoxOpen, faTimes} from '@fortawesome/free-solid-svg-icons';
import {fromEvent, merge, of} from 'rxjs';
import {debounceTime, switchMap, tap, map, filter, catchError} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs/Rx';
import {ProductSearchResponse} from '../product';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {
    INTERNAL_ERROR_MSG,
    INTERNAL_SERVER_ERROR,
    SERVICE_UNAVAILABLE,
    SERVICE_UNAVAILABLE_MSG
} from '../product-utils';
import {MODE_EDIT, MODE_VIEW} from '../form/product-form.component';
import {MessageResponse} from '../../index';

const XS_SCREEN_WIDTH = 400;
const SMALL_SCREEN_WIDTH = 576;

@Component({
    selector: 'app-product-dashboard',
    templateUrl: './product-dashboard.component.html',
    styleUrls: ['./product-dashboard.component.css']
})
export class ProductDashboardComponent implements OnInit, OnDestroy {



    faPlus = faPlus;
    faSearch = faSearch;
    faBoxOpen = faBoxOpen;
    faTimes = faTimes;



    @ViewChild('searchInput') searchInput;

    pageSelected: Subject<number> = new Subject<number>();
    searchSub: Subscription;

    selectedProduct: Product;
    errorMessage: string;

    searchText: string = '';
    minOffset: number = 0;
    currentOffset: number = 0;
    products: Product[] = [];
    numResults: number = 0;
    pageSize: number = 25;

    isLoadingPage = false;

    constructor(private productService: ProductService, private router: Router) {
    }

    ngOnInit(): void {
        this.isLoadingPage = true;
        const initialLoad$ = this.productService.searchProducts(this.searchText, this.currentOffset).pipe(
            catchError(error  => this.handleError(error)),
            tap( _ => this.isLoadingPage = false )
        );

        const searchInput$ = fromEvent<Event>(this.searchInput.nativeElement, 'input').pipe(
            debounceTime(150),
            map( event => (<HTMLInputElement>event.target).value),
            tap( searchText => this.searchText = searchText),
            tap( _ => this.isLoadingPage = true ),
            tap( _ => this.products = [] ),
            tap( _ => this.minOffset = 0),
            tap( _ => this.numResults = 0 ),
            tap( _ => this.errorMessage = null ),
            switchMap( searchText => this.productService.searchProducts(searchText, 0).pipe(
                catchError(error  => this.handleError(error)),
            )),
            tap( _ => this.isLoadingPage = false ),
        );

        const pageChange$ = this.pageSelected.pipe(
            tap( val => console.log(val)),
            tap( _ => this.products = [] ),
            tap( _ => this.isLoadingPage = true ),
            tap( _ => this.errorMessage = null ),
            switchMap( page => this.productService.searchProducts(this.searchText, this.toOffset(page)).pipe(
                catchError(error  => this.handleError(error)),
            )),
            tap( _ => this.isLoadingPage = false ),

        );

        this.searchSub = merge(initialLoad$, searchInput$, pageChange$).pipe(
            filter(response => response && response !== {})
        ).subscribe(
            response => this.updateProductsPage(response),
            error => console.log('Error', error),
            () => { console.log('Method has been completed'); }
        );
    }

    isXSScreen() {
        return window.innerWidth < XS_SCREEN_WIDTH;
    }

    private toOffset(page: number) {
        return Math.max(0, (page - 1) * this.pageSize);
    }

    private updateProductsPage(response: ProductSearchResponse | {}) {
        if (!response || response === {}) {
            return;
        }

        this.isLoadingPage = false;

        const searchResponse: ProductSearchResponse = <ProductSearchResponse>response;
        this.currentOffset = searchResponse.currentOffset;
        this.products = searchResponse.products;
        this.numResults = searchResponse.numResults;
        this.pageSize = searchResponse.pageSize;
    }

    private handleError(error): Observable<ProductSearchResponse | {}> {
        this.isLoadingPage = false;
        if (error instanceof HttpErrorResponse) {
            this.errorMessage = this.getHttpErrorMessage(error);
        } else {
            this.errorMessage = SERVICE_UNAVAILABLE_MSG;
        }

        return of(null);

    }

    private getHttpErrorMessage(error: HttpErrorResponse) {
        if (error.status === SERVICE_UNAVAILABLE) {
            return SERVICE_UNAVAILABLE_MSG;
        }

        if (error.status > INTERNAL_SERVER_ERROR) {
            return INTERNAL_ERROR_MSG;
        }

        const clientError = error!.error!.error!.message;
        return clientError == null ? SERVICE_UNAVAILABLE_MSG : clientError;
    }


    onProductSelected(product: Product) {
        // Anything with a small screen (width <= 576px) will not be able to see preview.
        // Go to detail screen.
        if (window.innerWidth <= SMALL_SCREEN_WIDTH) {
            this.viewProduct(product.websafeKey);
            return;
        }
        this.selectedProduct = product;
    }

    addProduct() {
        this.router.navigate(['product'])
    }

    viewProduct(websafeKey: string) {
        this.router.navigate(['product'], {queryParams: {key: websafeKey, mode:MODE_VIEW}})
    }

    editProduct(websafeKey: string) {
        this.router.navigate(['product'], {queryParams: {key: websafeKey, mode:MODE_EDIT}})
    }

    deleteProduct(websafeKey: string) {
        this.isLoadingPage = true;
        this.selectedProduct = null;
        this.productService.deleteProduct(websafeKey).pipe(
            tap(_ => console.log('Deleting product', websafeKey)),
            catchError(error => this.handleError(error)),
            filter(response => response != null && response != {} && (<MessageResponse>response).success),
            switchMap(page => this.productService.searchProducts(this.searchText, this.currentOffset).pipe(
                catchError(error => this.handleError(error)),
            )),
            filter(response => response && response !== {}),
            map(response => <ProductSearchResponse>response)
        ).subscribe(
            response => this.updateProductsPage(response),
            error => console.log('Error', error),
            () => {console.log('Method has been completed');}
        );

    }

    ngOnDestroy(): void {
        this.searchSub.unsubscribe();
    }

}
