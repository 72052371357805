import {Component, ViewChild} from "@angular/core";
/**
 * Created by Karl on 24/04/2017.
 */

@Component({
    moduleId: module.id,
    selector: 'landing-page',
    template:
    `<div class="row align-items-center">
        <div class="col-md-6">
        </div>
    </div>
    `,
    styleUrls: ['./landing.component.css']
}) export class LandingPageComponent {
}
