import {ValidatorFn, AbstractControl} from "@angular/forms";

/**
 *
 * Created by Karl on 13/3/18.
 */
export function endDateValidationFn(startDate: Date): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        console.log('Start date', startDate.getTime(), 'End date', control.value.getTime());
        const isValid = startDate.getTime() <= control.value.getTime();
        return isValid ? null : {'reason': 'End date is before start date.'}
    }
}
