import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-api-docs',
    templateUrl: './api-docs.component.html',
    styleUrls: ['./api-docs.component.css']
})
export class ApiDocsComponent implements OnInit {

    //
    offsetPixels: number = -60;

    currentUrl: string;

    constructor(private route: ActivatedRoute, private router: Router, private location: Location) {
    }

    ngOnInit() {
        this.currentUrl = this.router.url;
        this.router.events.subscribe(event => this.currentUrl = this.router.url);

        // Subscribe to the fragment that was initially loaded
        this.route.fragment.pipe(
            first()
        ).subscribe( fragment => this.navigateTo(!fragment ? 'introduction' : fragment));
    }

    onIdChange(elId: string) {
        if (!elId) {
            return;
        }

        this.currentUrl = `/api#${elId}`;
        this.location.replaceState(this.currentUrl);
    }

    navigateTo(elId: string) {
        const target = document.querySelector(`#${elId}`);
        if (!target) {
            return;
        }
        target.scrollIntoView({ behavior: 'auto', block: 'start'});
        window.scrollBy(0, this.offsetPixels);
        this.onIdChange( elId );
    }
}
