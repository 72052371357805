// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    envName: 'staging',
    firebase: {
        apiKey: "AIzaSyBK0hM4shwJ1w70oFhqx2geiv-SvCE1vkA",
        authDomain: "tilly-vendor-aus-staging.firebaseapp.com",
        databaseURL: "https://tilly-vendor-aus-staging.firebaseio.com",
        projectId: "tilly-vendor-aus-staging",
        storageBucket: "tilly-vendor-aus-staging.appspot.com",
        messagingSenderId: "939004984692"
    },
    hostUrl: 'https://tilly-aus-staging.appspot.com',
    reportingUrl: 'https://vendor-reporting-dot-tilly-aus-staging.appspot.com',
    streamUrl: 'https://tilly-stream-aus-staging.firebaseapp.com',
    gmapsKey: 'AIzaSyAd4-3vcFyE66YSIhH4o61cMyOelKIYvYg',
    disabled: {
        dashboard: false,
        offers: false,
        admin: false
    }
};



