import {Pipe, PipeTransform} from '@angular/core';
import {Store} from './store';

@Pipe({
    name: 'shortAddress'
})
export class ShortAddressPipe implements PipeTransform {

    transform(store: Store): string {
        let address: string = store.address || '';
        address = address.trim();

        let suburb: string = store.suburb || '';
        suburb = suburb.trim();
        if (!suburb) {
            return address;
        }

        const index = address.lastIndexOf(suburb);
        if (index == -1) {
            return address
        }

        const end = index + store.suburb.length;

        return address.substr(0, end);
    }

}
