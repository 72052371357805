/**
 * Created by Karl on 13/3/18.
 */
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Offer} from "../offer";
import {OffersService} from "../services/offers.service";


@Component({
    moduleId: module.id,
    selector: 'offers-list',
    template: `
                <div class="row">
                    <div class="col-lg-12">
                        <span (click)="createNewOffer()">New</span>
                    </div>
                </div>
                <ul>
                    <li *ngFor="let offer of offers; let i = index">
                        <offer [offer]="offer" (deleteOffer)="deleteOffer(i)"></offer>
                    </li>
                </ul>
    `
})
export class OffersListComponent implements OnInit {

    constructor(private router: Router, private offerService: OffersService ) {}

    offers: Offer[] = [];

    ngOnInit(): void {
        this.offerService.getOffers().subscribe( offers => this.offers = offers );
    }

    createNewOffer() {
        this.router.navigate(['offers', 'create'])
    }

    deleteOffer( index: number ) {
        const offer: Offer = this.offers[index];
        this.offerService.deleteOffer( offer.websafeKey )
            .subscribe( message => {
                console.log('DEBUG', 'deleteOfferResponse', message);
                this.offers.splice(index, 1);
            });
    }
}
