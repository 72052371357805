/**
 * Created by Karl on 12/3/18.
 */

import {Component} from "@angular/core";
import {Router} from "@angular/router";

@Component({
    moduleId: module.id,
    selector: 'offer-dashboard',
    template: `
        <div class="row">
            <div class="col-12">
                <header class="dashboard-nav">
                    <h1>Offers</h1>
                </header>
                <router-outlet></router-outlet>
            </div>
        </div>
    `,
    styleUrls: ['./offers-dashboard.component.css']
})
export class OffersDashboardComponent {

    constructor(private router: Router) {}
}
