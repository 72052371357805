import {AbstractControl, ValidatorFn} from '@angular/forms';

export const UPC_LENGTH = 12;
export const EAN_LENGTH = 13;


export function validateBarcode(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        return isValidBarcode(control.value) ? null : {'reason': 'Barcode is not a valid UPC-A or EAN-13 code.'}
    }
}

export function isValidBarcode(barcode: string): boolean {
    return isValidUPCA(barcode) || isValidEAN13(barcode) || isValidCode128(barcode);
}

export function isValidEAN13(barcode: string) {
    if (!barcode) {
        return false;
    }

    if (barcode.length !== EAN_LENGTH) {
        return false;
    }

    let nums: number[] = barcode.split('').map(char => +char);
    let sum = 0;
    for (let i=0; i < nums.length; i++) {
        sum += i % 2 === 0 ? nums[i]  : 3 * nums[i];
    }

    return sum % 10 === 0
}

export function isValidUPCA(barcode: string) {
    if (!barcode) {
        return false;
    }

    if (barcode.length !== UPC_LENGTH) {
        return false;
    }

    let nums: number[] = barcode.split('').map(char => +char);
    let sum = 0;
    for (let i=0; i < nums.length; i++) {
        sum += i % 2 === 0 ? 3 * nums[i]  : nums[i];
    }
    return sum % 10 === 0
}

export function isValidCode128(barcode: string) {
    // TODO Match the Code 128 standard.
    if (!barcode) {
        return false;
    }
    let trimmedBarcode = barcode.trim();
    return 0 < trimmedBarcode.length && trimmedBarcode.length <= 48;
}

export function randomDigit() {
    return Math.floor((Math.random() * 10) + 1) - 1;
}

export function generateBarcode(): string {
    let sum = 0;
    let digits = [];
    for (let i=0; i<UPC_LENGTH; i++) {
        const digit = randomDigit();
        digits.push(digit);
        sum += i % 2 === 0 ? digit  : 3 * digit;
    }
    const checkDigit = (10 - (sum % 10)) % 10
    digits.push(checkDigit);
    return  digits.reduce( (partial, digit) => partial + digit, '');
}
